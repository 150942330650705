<template>
  <div class="space-bottom">
    <b-tabs content-class="py-3" v-model="tabIndex">
      <b-tab title="หน้าหลัก">
        <b-row class="align-items-center">
          <b-col md="3" cols="12">
            <div class="d-flex mt-2">
              <p class="mt-2 mr-2 title">เลขที่</p>

              <InputText
                type="text"
                className=" bg-second flex-grow-1"
                placeholder="เลขที่"
                textField=""
                :value="form.document_no"
                disabled
              />
            </div>
          </b-col>
          <b-col md="3" cols="12">
            <div class="d-flex mt-2">
              <p class="mt-2 mr-2 title">วันที่</p>
              <InputText
                type="text"
                className=" bg-second flex-grow-1 "
                placeholder="วันที่"
                textField=""
                :value="$moment(form.created_time).format('DD/MM/YYYY (HH:mm)')"
                disabled
              />
            </div>
          </b-col>
          <b-col md="6" cols="12" v-if="id != 0">
            <div
              class="d-flex align-items-center justify-content-end"
              style="column-gap: 10px"
            >
              <div>สถานะ: {{ form.inventory_transfer_status_name }}</div>
              <div>
                อัพเดทล่าสุด:
                {{ $moment(form.updated_time).format("DD/MM/YYYY") }}
              </div>
              <b-button
                variant="primary-color"
                v-if="form.cancellable && id != 0"
                @click="confirmModal(1)"
                >ยกเลิกเอกสาร</b-button
              >
              <b-button
                variant="primary-color"
                v-if="form.confirm_transfer"
                @click="confirmModal(2)"
              >
                ยืนยันการจัดส่ง
              </b-button>
              <b-button
                variant="primary-color"
                v-if="form.confirm_receive"
                @click="confirmModal(3)"
              >
                ยืนยันการรับของ
              </b-button>
              <b-button
                variant="outline-primary-color"
                v-if="form.exportable"
                @click="exportDetail"
                >ออกรายงาน</b-button
              >
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-4"
          ><b-col md="6">
            <p class="title">สาขาต้นทาง</p>
            <b-card class="mt-2">
              <!-- v-model="filter.search" -->
              <InputText
                v-if="id == 0 || form.isDraft"
                type="text"
                faIcon="search"
                className=" mb-2 bg-second w-100"
                placeholder="ค้นหาสาขาต้นทาง"
                @selectAutoComplete="(val) => selectBranch(val, 1)"
                :optionsAutoComplete="documentDetail.source_branches"
                textField="branch_name"
                :optionValue="form.source_branch_id"
              ></InputText>
              <!-- <InputText
                type="text"
                faIcon="search"
                className="mb-2 bg-second w-100"
                placeholder="ค้นหาสาขาต้นทาง"
                @onClickIcon="onSearch"
                textField=""
                @onEnter="onSearch"
              /> -->

              <div
                class="d-flex mb-2"
                v-for="item in sourceBranch"
                :key="item.label"
              >
                <p class="text-label text-light-gray">{{ item.label }}</p>
                <p class="text-light-gray">:</p>
                <p>&nbsp;{{ form.source_branch[item.key] || "-" }}</p>
              </div>
            </b-card>
          </b-col>
          <b-col md="6">
            <p class="title">สาขาปลายทาง</p>
            <b-card class="mt-2">
              <InputText
                v-if="id == 0 || form.isDraft"
                type="text"
                faIcon="search"
                className=" mb-2 bg-second w-100"
                placeholder="ค้นหาสาขาปลายทาง"
                @selectAutoComplete="(val) => selectBranch(val, 2)"
                :optionsAutoComplete="documentDetail.destination_branches"
                textField="branch_name"
                @onClickIcon="onSearch"
                :optionValue="form.destination_branch_id"
              ></InputText>

              <div
                class="d-flex mb-2"
                v-for="item in destinationBranch"
                :key="item.label"
              >
                <p class="text-label text-light-gray">{{ item.label }}</p>
                <p class="text-light-gray">:</p>
                <p>&nbsp;{{ form.destination_branch[item.key] || "-" }}</p>
              </div>
            </b-card>
          </b-col>
          <b-col cols="12">
            <InputTextArea
              name="text"
              rows="4"
              v-model="form.remark"
              textFloat="หมายเหตุ"
            />
          </b-col>
          <b-col cols="12">
            <template v-if="form.cancellable">
              <p class="title">เพิ่มสินค้า</p>

              <div class="title-tabs d-flex w-100">
                <InputText
                  v-model="filter.search"
                  type="text"
                  className=" bg-second flex-grow-1 custom-add-product"
                  placeholder="ค้นหาสินค้า"
                  @selectAutoComplete="setProductSelect"
                  :optionsAutoComplete="productList"
                  textField="display_name"
                  @onEnter="searchProduct()"
                ></InputText>
                <b-input-group-append is-text class="custom-height-bt">
                  <b-iconstack font-scale="2" type="submit" @click="onSearch">
                    <b-icon
                      stacked
                      icon="plus"
                      scale="1"
                      variant="white"
                    ></b-icon>
                  </b-iconstack>
                  <p>เพิ่ม</p>
                </b-input-group-append>
              </div></template
            >

            <p class="title mb-3">รายการสินค้า</p>
            <div class="card p-3">
              <InputText
                v-model="searchDisplayProduct"
                type="text"
                faIcon="search"
                className="mb-3 bg-second w-30"
                placeholder="ค้นหาบาร์โค้ด, ชื่อสินค้า, Product code"
                @onClickIcon="handleSearchDisplayProduct"
                @onEnter="handleSearchDisplayProduct"
              />
              <b-table
                responsive
                striped
                hover
                show-empty
                empty-text="No matching records found"
                class="table-main table-custom"
                :no-border-collapse="false"
                :busy="isBusy"
                :items="items"
                :fields="fields"
              >
                <template v-slot:cell(index)="{ item }">
                  {{ item.id + 1 }}
                </template>
                <template v-slot:cell(serial_name)="{ item }">
                  <div v-if="item.is_serial">
                    <p v-if="item.serial_name">
                      Serial : {{ item.serial_name }}
                      <span
                        class="text-link"
                        @click="selectSerial(item, item.id)"
                        >เปลี่ยน</span
                      >
                    </p>
                    <p
                      class="text-link"
                      v-else
                      @click="selectSerial(item, item.id)"
                    >
                      กรุณาระบุ Serial
                    </p>
                  </div>
                  <p v-else>-</p>
                </template>
                <template v-slot:cell(action)="{ item }">
                  <font-awesome-icon
                    icon="trash-alt"
                    title="delete-btn"
                    size="lg"
                    class="pointer text-primary icon-delete"
                    @click.stop="openModalDelete(item.id)"
                  />
                </template>
                <template v-slot:cell(product_image)="{ item }">
                  <img :src="item.product_image" class="img-size" />
                </template>
                <template v-slot:cell(quantity)="{ item }">
                  <InputText
                    v-model="item.quantity"
                    textFloat=""
                    :disabled="
                      item.is_serial ? true : false || !form.cancellable
                    "
                    type="number"
                  >
                  </InputText>
                </template>
                <template v-slot:table-busy>
                  <div class="text-center text-black my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Loading...</strong>
                  </div>
                </template>
              </b-table>
              <Pagination
                @handleChangeTake="handleChangeTake"
                :pageOptions="pageOptions"
                :filter="filter"
                :rows="rows"
                @pagination="pagination"
              />
            </div>
          </b-col>
        </b-row>
        <ModalSelectSerial
          ref="modalSerialList"
          @selectSerial="handleSelectSerial"
          @unselectItem="handleSelectSerial"
          :branchId="branchId"
          :productList="filteredItems"
        />
      </b-tab>

      <b-tab title="ประวัติ" :disabled="id == 0">
        <TableHistory :items="form.status_history" />
      </b-tab>
    </b-tabs>
    <FooterAction
      :routePath="'/inventory-transfer/' + $route.params.id"
      @submit="confirmAlertModal()"
      @customAction="confirmAlertModal(true)"
      :textCustom="
        (id != 0 && form.inventory_transfer_status_id == 6) || id == 0
          ? 'บันทึกฉบับร่าง'
          : null
      "
      :hideSubmit="!form.cancellable"
    ></FooterAction
    ><ModalAlertConfirm
      ref="modalAlertConfirm"
      msg="ยันยันการทำรายการ"
      text="หลังจากยืนยันแล้วจะไม่สามารถแก้ไขได้"
      @confirm="(val) => saveForm(val)"
    />
    <ModalNote
      ref="ModalNote"
      :msg="modalMessage"
      text=""
      @confirm="modalCallBack"
    />
  </div>
</template>
<script>
import InputText from "@/components/inputs/InputText";
import InputTextArea from "../../../components/inputs/InputTextArea.vue";
import Pagination from "@/components/Pagination";
import FooterAction from "@/components/FooterAction";
import ModalSelectSerial from "./components/ModalSelectSerial.vue";
import TableHistory from "./components/TableHistory";
import { mapGetters } from "vuex";
import ModalAlertConfirm from "@/components/modal/alert/ModalAlertConfirm";
import ModalNote from "./components/ModalAlertNote";
export default {
  components: {
    InputText,
    InputTextArea,
    Pagination,
    FooterAction,
    ModalSelectSerial,
    TableHistory,
    ModalAlertConfirm,
    ModalNote,
  },
  data() {
    return {
      fields: [
        { label: "#", key: "index" },
        { label: "Image", key: "product_image", thStyle: { width: "10%" } },
        { label: "Barcode", key: "barcode" },
        {
          label: "Name",
          key: "product_name",
          tdClass: "text-left",
          thStyle: { width: "30%" },
        },
        { label: "Serial", key: "serial_name", thStyle: { width: "10%" } },
        { label: "Quantity", key: "quantity", thStyle: { width: "10%" } },
        { label: "Action", key: "action", thStyle: { width: "10%" } },
      ],
      tabIndex: 0,
      isBusy: false,

      // form: {
      //   number: "",
      //   date: "",
      //   detail: "",
      // },
      // filter: {
      //     originalBranch: "",
      //     destinationBranch: "",
      //   },
      // },
      filter: {
        search: "",
        page: 1,
        take: 10,
      },
      sourceBranch: [
        {
          label: "ชื่อสาขา",
          key: "branch_name",
        },
        { label: "รหัส", key: "code" },
        { label: "ประเภท", key: "branch_type_name" },
        { label: "ผู้ส่ง", key: "action_by" },
        { label: "วันที่ส่งออก", key: "action_date" },
      ],
      destinationBranch: [
        {
          label: "ชื่อสาขา",
          key: "branch_name",
        },
        { label: "รหัส", key: "code" },
        { label: "ประเภท", key: "branch_type_name" },
        { label: "ผู้ส่ง", key: "action_by" },
        { label: "วันที่ส่งออก", key: "action_date" },
      ],
      productList: [],

      pageOptions: [
        { value: 5, text: "5 / page" },
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      items: [],
      rows: 0,
      id: this.$route.params.detail_id,
      form: {
        inventory_transfer_id: 0, //0 insert
        inventory_transfer_document_id: this.$route.params.id,
        source_branch_id: 0,
        destination_branch_id: 0,
        isDraft: false,
        remark: "",
        status_history: [],
        destination_branch: {
          branch_id: 0,
          branch_name: "",
          branch_short_name: "",
          code: "",
          branch_type_name: "",
          action_by: null,
          action_date: null,
        },
        source_branch: {
          branch_id: 0,
          branch_name: "",
          branch_short_name: "",
          code: "",
          branch_type_name: "",
          action_by: null,
          action_date: null,
        },
        cancellable: true,
        products: [],
      },
      isDraft: false,
      allItem: [],
      rowsFormData: [],
      filteredItems: [],
      searchDisplayProduct: "",
      documentDetail: { destination_branches: [], source_branches: [] },
      modalCallBack: () => {},
    };
  },
  created() {
    if (this.$route.params.detail_id != 0) this.getDetail();
    else this.getDocumentDetail();
  },
  computed: {
    ...mapGetters({
      mainLoading: "getMainLoading",
      branchId: "getBranchId",
    }),
  },

  methods: {
    async getDetail() {
      if (this.id == 0) return;
      await this.$store.dispatch("setMainLoading", true);
      this.isBusy = true;
      const res = await this.axios(
        `${this.$baseUrl}/Inventory/Transfer/${this.id}`
      );

      this.items = res.detail.products.map((product, index) => ({
        ...product,
        id: index,
      }));
      this.form = res.detail;
      this.allItem = this.items;

      this.rows = this.allItem.length;
      this.filteredItems = this.allItem;
      this.updateDisplayedItems();
      this.isBusy = false;
      await this.$store.dispatch("setMainLoading", false);
    },
    async getDocumentDetail() {
      await this.$store.dispatch("setMainLoading", true);

      const res = await this.axios(
        `${this.$baseUrl}/Inventory/Document/${this.$route.params.id}`
      );
      await this.$store.dispatch("setMainLoading", false);
      if (!res.detail.can_create)
        return this.errorAlert("ไม่มีสิทธิ์ในการสร้าง Document นี้").then(() =>
          this.$router.replace("/inventory-transfer/" + this.$route.params.id)
        );
      this.documentDetail = res.detail;
    },
    async saveForm() {
      let products = this.allItem.map((product) => ({
        product_id: product.product_id,
        quantity: product.quantity,
        serial_id: product.serial_id || null,
        serial_name: product.serial_name,
      }));
      await this.$store.dispatch("setMainLoading", true);

      let payload = {
        ...this.form,
        isDraft: this.isDraft,
        products: [...products],
      };
      this.axios
        .post(`${this.$baseUrl}/Inventory/Transfer/Save`, payload)
        .then(async (data) => {
          await this.$store.dispatch("setMainLoading", false);
          if (data.result == 1) {
            this.successAlert();
            this.$router
              .push({
                path: `/inventory-transfer/${this.$route.params.id}`,
              })
              .catch(() => {});
          } else {
            this.warningAlert(data.message);
          }
        });
    },
    async searchProduct() {
      const res = await this.axios.post(
        `${this.$baseUrl}/Inventory/Transfer/SearchProduct`,
        this.filter
      );
      if (res.detail.look_up_product) {
        this.setProductSelect(res.detail.look_up_product);
        return;
      }
      this.productList = res.detail.search_product;
    },
    onSearch() {},
    setProductSelect(product) {
      const productIndex = this.allItem.findIndex(
        (item) => item.product_id === product.product_id
      );

      const dupeSerial = this.allItem.findIndex(
        (item) => item.serial_name == product.serial
      );

      if (productIndex !== -1 && !product.is_serial) {
        this.allItem[productIndex].quantity += 1;
      } else if ((!dupeSerial && product.serial) || !product.current_stock) {
        this.warningAlert(
          !dupeSerial && product.serial ? "Serial ซ้ำ" : "สินค้าหมด"
        );
      } else {
        let _product = {
          product_image: product.image,
          barcode: product.barcode,
          product_name: product.product_name,
          quantity: 1,
          serial_name: product.serial,
          is_serial: product.is_serial,
          serial_id: product.inventory_product_serial_id,
          product_id: product.product_id,
          id: this.allItem.length,
        };

        this.allItem.push({ ..._product });
        this.filter.page = 1;
        this.updateDisplayedItems();
      }

      const maxItems = this.filter.take;
      if (this.allItem.length > maxItems) {
        this.items = this.allItem.slice(0, maxItems);
      } else {
        this.items = this.allItem;
      }
      this.rows = this.allItem.length;
      this.filteredItems = this.allItem;
    },
    selectBranch(val, type) {
      if (type == 1)
        (this.form.source_branch = val),
          (this.form.source_branch_id = val.branch_id);
      if (type == 2)
        (this.form.destination_branch = val),
          (this.form.destination_branch_id = val.branch_id);
      this.$nextTick();
    },
    openModalDelete(id) {
      this.confirmAlert("Confirm delete product?").then((val) => {
        if (val.isConfirmed) {
          this.deleteProduct(id);
        }
      });
    },
    deleteProduct(item) {
      this.allItem = this.allItem.filter((x) => x.id !== item);

      this.filteredItems = this.filteredItems.filter(
        (x, index) => x.id !== item
      );
      this.updateDisplayedItems(true);
    },
    handleSearchDisplayProduct() {
      const searchText = this.searchDisplayProduct.toLowerCase();
      if (searchText) {
        this.filteredItems = this.allItem.filter(
          (item) =>
            item.product_name.toLowerCase().includes(searchText) ||
            item.barcode.toLowerCase().includes(searchText)
        );
      } else {
        this.filteredItems = [...this.allItem];
      }
      this.filter.page = 1;
      this.updateDisplayedItems();
    },
    pagination(val) {
      this.filter.page = val;
      this.updateDisplayedItems();
    },
    handleChangeTake() {
      this.filter.page = 1;
      this.updateDisplayedItems();
    },
    updateDisplayedItems(isDelete) {
      this.isBusy = true;
      if (isDelete) {
        this.filter.page = 1;
      }
      const start = (this.filter.page - 1) * this.filter.take;
      const end = start + this.filter.take;

      this.items = this.filteredItems.slice(start, end);
      this.rows = this.filteredItems.length;
      this.isBusy = false;
    },
    async selectSerial(product, index) {
      this.$refs.modalSerialList.show(product, index);
    },
    handleSelectSerial(serial, productId, index, serial_id) {
      let prodictDisplay = this.filteredItems[index];
      if (serial) {
        this.select_serial = serial;
        prodictDisplay.serial_name = serial.serial;
        prodictDisplay.serial_id = serial_id;
      } else {
        prodictDisplay.serial_name = null;
        prodictDisplay.serial_id = null;
      }
      this.updateDisplayedItems();
      this.$refs.modalSerialList.hide();
    },
    async cancel(note) {
      await this.$store.dispatch("setMainLoading", true);
      const res = await this.axios.post(
        `${this.$baseUrl}/Inventory/Transfer/Cancel`,
        {
          inventory_transfer_id: this.id,
          remark: note,
        }
      );
      if (res.result == 1)
        this.successAlert(res.detail.message), this.getDetail();
      else this.errorAlert(res.detail.message);
      await this.$store.dispatch("setMainLoading", false);
    },
    async confirmTransfer(note) {
      await this.$store.dispatch("setMainLoading", true);
      const res = await this.axios.post(
        `${this.$baseUrl}/Inventory/Transfer/ConfirmTransfer`,
        {
          inventory_transfer_id: this.id,
          remark: note,
        }
      );
      if (res.result == 1)
        this.successAlert(res.detail.message), this.getDetail();
      else this.errorAlert(res.detail.message);
      await this.$store.dispatch("setMainLoading", false);
    },
    async confirmRecieve(note) {
      await this.$store.dispatch("setMainLoading", true);
      const res = await this.axios.post(
        `${this.$baseUrl}/Inventory/Transfer/ConfirmReceive`,
        {
          inventory_transfer_id: this.id,
          remark: note,
        }
      );
      if (res.result == 1)
        this.successAlert(res.detail.message), this.getDetail();
      else this.errorAlert(res.detail.message);
      await this.$store.dispatch("setMainLoading", false);
    },
    async exportDetail() {
      const res = await this.axios(
        `${this.$baseUrl}/Inventory/Transfer/${this.id}/export`,

        {
          responseType: "blob",
        }
      );

      let data = res;

      var fileURL = window.URL.createObjectURL(new Blob([data]));
      var fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.setAttribute("download", this.form.document_no + `.xlsx`);
      document.body.appendChild(fileLink);
      fileLink.click();
    },
    confirmAlertModal(isDraft = false) {
      this.isDraft = isDraft;
      this.$refs.modalAlertConfirm.show(isDraft);
    },
    confirmModal(type, id) {
      this.$refs.ModalNote.show();

      switch (type) {
        case 1:
          this.modalMessage = "ยืนยันยกเลิกรายการ";
          this.modalCallBack = (note) => this.cancel(note);
          break;
        case 2:
          this.modalMessage = "ยืนยันการจัดส่ง";
          this.modalCallBack = (note) => this.confirmTransfer(note);
          break;
        case 3:
          this.modalMessage = "ยืนยันการรับของ";
          this.modalCallBack = (note) => this.confirmRecieve(note);
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.w-85 {
  width: 85%;
}

.title {
  color: #333 !important;
  font-size: 16x !important;
  font-weight: bold !important;
  position: relative;
}

.text-label {
  width: 100px;
}

.text-light-gray {
  color: #9b9b9b;
}

::v-deep .input-group-text {
  background-color: var(--primary-color) !important;
  color: white !important;
}
// .custom-add-product ::v-deep .input-custom input[data-v-32b8d6e1] {
//   border-radius: 0px !important;
// }

.custom-height-bt {
  height: 38.66px !important;
}

.img-size {
  width: 60px;
  height: 60px;
}

.w-30 {
  width: 30%;
}

// ::v-deep .c-main > .container-fluid {
//   height: calc(100vh - 80px) !important;
//   overflow: scroll !important;
// }
.space-bottom {
  padding-bottom: 80px;
}
</style>
